<template>
    <div class="container-lg mt-3 mt-md-5 mb-3 mb-md-5">

        <div v-if="survey">

            <Alerts :_success="success" :_error="error"></Alerts>

            <div v-if="wait" class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...please wait</span>
                </div>
            </div>

            <div v-if="!wait && questions.length !== 0 && survey.published && survey.respondents_count !== 0"
                 class="alert alert-warning">
                <h6 class="mb-1"><strong>This survey is collecting responses and questions cannot be edited.</strong>
                </h6>
                <p class="mb-0">You can choose to deactivate, archive or delete this survey.</p>
            </div>


            <div v-if="!wait && questions.length !== 0 && survey.published && survey.respondents_count == 0"
                 class="alert alert-info">
                <h6 class="mb-1"><strong>This survey is active and can start collecting responses.</strong></h6>
                <p class="mb-3">Your survey has not collected any responses yet. Click 'unpublish' if you would like to
                    add or edit questions.</p>
                <button class="custom-btn custom-btn-secondary" @click.prevent="unPublishSurvey()">
                    <font-awesome-icon icon="undo"/>
                    Unpublish
                </button>
            </div>

            <div class="row g-2 mb-3">
                <div class="col-md-3">
                    <label class="form-label">Switch language</label>
                    <select class="form-select" v-model="lang" @change="updateSurveyLang()">
                        <option v-for="l in languages" :value="l.code" :key="l.code">{{ l.name }}</option>
                    </select>
                </div>
                <div class="col-md-3" v-if="questions.length >= 5">
                    <label class="form-label">Jump to</label>
                    <select class="form-select" @change="scrollTo($event)">
                        <option :value="qn.id" v-for="qn in questions" :key="qn.id">
                            <template v-if="qn.operator">
                                {{ '[Branch] ' + qn.title }}
                            </template>
                            <template v-else>
                                {{ qn.title }}
                            </template>
                        </option>
                    </select>
                </div>
            </div>

            <div class="survey-text-editable">
                <router-link class="survey-title" :to="'/profile/surveys/create/'+survey.code"
                             title="Click to edit survey title">
                    <span v-if="survey.title">{{ survey.title }}</span>
                    <span v-else class="text-muted">
                        <template v-if="lang == 'fr'">Cliquez pour ajouter un titre</template>
                        <template v-else-if="lang == 'swa'">Bofya ili kuongeza kichwa</template>
                        <template v-else>Click to add title</template>
                    </span>
                </router-link>
            </div>

            <div class="survey-text-editable">
                <router-link class="survey-desc" :to="'/profile/surveys/create/'+survey.code"
                             title="Click to edit survey description">
                    <span v-if="survey.description">{{ survey.description }}</span>
                    <span v-else class="text-muted">
                        <template v-if="lang == 'fr'">Cliquez pour ajouter une description</template>
                        <template v-if="lang == 'swa'">Bofya ili kuongeza maelezo</template>
                        <template v-else>Click to add description</template>
                    </span>
                </router-link>
            </div>

            <div>

                <!--Questions-->
                <div class="questionnaire-container">

                    <!-- Empty store shows if question count == 0 -->
                    <div v-if="!wait && !survey.published && questions.length == 0" class="posts-empty">
                        <div class="posts-empty-inner">
                            <div class="posts-empty-inner_img">
                                <img loading="lazy" src="@/assets/img/empty-document.png" width="80" height="80"
                                     alt="Empty survey"/>
                            </div>
                            <h4 v-if="lang == 'fr'">Cette enquête n'a pas de questions.</h4>
                            <h4 v-else-if="lang == 'swa'">Utafiti huu hauna maswali.</h4>
                            <h4 v-else>This survey has no questions.</h4>
                            <h6 v-if="lang == 'fr'" class="mb-4">Les questions que vous créez pour cette enquête
                                apparaîtront ici.</h6>
                            <h6 v-else-if="lang == 'swa'" class="mb-4">Maswali utakayounda kwa ajili ya utafiti huu
                                yataonekana hapa.</h6>
                            <h6 v-else class="mb-4">Questions you create for this survey will appear here.</h6>
                            <button @click.prevent="newQuestion()" class="custom-btn custom-btn-primary">
                                <template v-if="lang == 'fr'">Ajouter une question +</template>
                                <template v-else-if="lang == 'swa'">Ongeza swali +</template>
                                <template v-else>Add a Question +</template>
                            </button>
                        </div>
                    </div>


                    <ul v-else class="questionnaire-list">

                        <!--                    <draggable-->
                        <!--                        v-model="questions"-->
                        <!--                        draggable=".questionnaire-list-item"-->
                        <!--                        ghost-class="ghost"-->
                        <!--                    >-->
                        <!--                        <transition-group type="transition" name="flip-list">-->
                        <li v-for="qn in questions" :key="qn.id"
                            :class="qn.show_when ? 'questionnaire-list-item conditional' : 'questionnaire-list-item'"
                            :id="qn.id">
                            <!--                            <span class="question-grip">-->
                            <!--                                <i class="fas fa-grip-horizontal"></i>-->
                            <!--                            </span>-->
                            <span class="question-content">
                                <div class="row mb-2">
                                        
                                        <!-- Question Title -->
                                        <div class="col-lg-8">

                                            <div class="mb-3 question-info">
                                                <span :id="qn.show_when ? `condition-tooltip-${qn.id}` : ''">
                                                    <sup
                                                        v-if="qn.required"
                                                        class="required font-weight-semibold"
                                                        title="You have marked this question as required.">
                                                        *
                                                    </sup>                                                    
                                                    Title
                                                    <font-awesome-icon
                                                        v-if="qn.show_when"
                                                        class="light-blue"
                                                        icon="code-branch"
                                                        id="condition-tooltip"
                                                    />
                                                    <b-tooltip :target="`condition-tooltip-${qn.id}`">
                                                        {{
                                                            qn.show_when ? `Show when answer to ${qn.show_when} ${qn.operator} "${qn.value_is}"` : ''
                                                        }}
                                                    </b-tooltip>                                                    
                                                </span>
                                                <p>
                                                    {{
                                                        qn.title ? qn.title :
                                                            lang == 'fr' ? '(Ajouter un titre à la question)' :
                                                                lang == 'swa' ? '(Ongeza kichwa cha swali)' :
                                                                    '(Add question title)'
                                                    }}
                                                </p>
                                            </div>

                                            <div v-if="qn.options" class="mb-sm-0 mb-2">

                                                <a class="icon-link" v-b-toggle :href="`#options-dropdown-${qn.id}`"
                                                   @click.prevent="!survey.published ? editQuestion(qn) : ''">
                                                    <button class="custom-btn custom-btn-xs custom-btn-outline">
                                                        Options &#40;{{ qn.getOptions.length }}&#41;
                                                        <font-awesome-icon v-if="survey.published" icon="chevron-down"/>
                                                    </button>
                                                </a>

                                                <b-collapse v-if="survey.published" class="mt-3"
                                                            :id="`options-dropdown-${qn.id}`">
                                                    <div class="option-list">
                                                        <ul>
                                                            <li v-for="option in qn.getOptions" :key="option">
                                                                <font-awesome-icon icon="check-square"/>
                                                                {{ option }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </b-collapse>

                                            </div>

                                        </div>

                                    <!-- Question Type -->
                                        <div class="col-lg-4 question-info">
                                            <span>Type</span>
                                            <p :class="`font-weight-medium type type-${qn.type}`"
                                               style="text-transform:capitalize;">{{ qn.type }}</p>
                                        </div>
                                        
                                </div>
                                </span>
                            <ul v-if="!survey.published && survey.canUpdate" class="question-actions">
                                <li>
                                    <button @click.prevent="newQuestion(qn)"
                                            class="custom-btn-xs custom-btn-success" title="Add new question">
                                        <font-awesome-icon icon="plus"/>
                                    </button>
                                </li>
                                <li>
                                    <button @click.prevent="editQuestion(qn)"
                                            class="custom-btn-xs custom-btn-primary" title="Edit question">
                                        <font-awesome-icon icon="pen"/>
                                    </button>
                                </li>
                                <li>
                                    <button @click.prevent="removeQuestion(qn)"
                                            class="custom-btn-xs custom-btn-danger" title="Delete question">
                                        <font-awesome-icon icon="trash-alt"/>
                                    </button>
                                </li>
                            </ul>
                            <b-collapse v-if="qn.type == 'single'" :id="`collapse-${qn.id}`" class="mt-2 p-4"
                                        @hidden="onCollapseHidden">
                                <span>Logic</span>
                                <div class="option-list">
                                    <ul class="list-group">
                                        <li v-for="option in qn.getOptions" :key="option"
                                            class="d-flex flex-column ">
                                            <div class="p-2 row">
                                                <div class="col-6">
                                                    <span>If Answer is...</span>
                                                    <i class="far fa-check-square fa-lg"></i>{{ option }}
                                                </div>
                                                <div class="col-6">
                                                    <select name="question_logic" id=""
                                                            class="form-select w-1/2">
                                                        <option value="">--choose one--</option>
                                                        <option v-for="next in questions" :key="next.id">
                                                            {{ next.title }}
                                                        </option>
                                                        <option value="End of Survey">End of Survey</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </b-collapse>
                        </li>
                        <!--                        </transition-group>-->
                        <!--                    </draggable>-->
                    </ul>

                </div>

                <b-sidebar
                    id="survey-sidebar"
                    :title="lang == 'fr' ? 'Ajouter' : lang == 'swa' ? 'Ongeza' : 'Add/Edit Question'"
                    shadow
                    backdrop
                    lazy
                    no-header
                    v-model="isSidebarOpen"
                >
                    <div class="p-3 bg-white">

                        <h6 class="mb-3">{{ question.id ? 'Edit Question' : 'Add Questtion' }}</h6>

                        <div class="mb-3">
                            <label class="form-label">
                                Switch language
                                <span class="form-label-tooltip" id="lang-switch">
                                    <i class="far fa-question-circle"></i>
                                    <b-tooltip target="lang-switch" placement="right">
                                        Easily switch between languages if you have set more than one language.
                                    </b-tooltip>
                                </span>

                            </label>
                            <select class="form-select" v-model="lang" @change="updateSurveyLang()">
                                <option v-for="l in languages" :value="l.code" :key="l.code">{{ l.name }}</option>
                            </select>
                        </div>

                        <form v-if="survey.canUpdate" @submit.prevent="createQuestion()">

                            <!-- Question title -->
                            <div class="mb-3">
                                <label class="form-label">Insert Question Here
                                    <span class="form-label-tooltip" id="question">
                                            <i class="far fa-question-circle"></i>
                                            <b-tooltip target="question" placement="right">
                                                Add a question on the input. Type out the question.
                                            </b-tooltip>
                                        </span>
                                </label>
                                <b-form-textarea
                                    v-model="question.title"
                                    :disabled="wait" rows="3"
                                    placeholder="Enter the title of your question"
                                    required
                                >
                                </b-form-textarea>
                            </div>

                            <!-- Question Type -->
                            <div class="mb-3">
                                <label class="form-label">Select Question Type
                                    <span class="form-label-tooltip" id="question_type">
                                            <i class="far fa-question-circle"></i>
                                            <b-tooltip target="question_type" placement="right">
                                                Select the question type that fits the question that you are asking.
                                            </b-tooltip>
                                        </span>
                                </label>
                                <b-form-select class="form-select" v-model="question.type" @change="questionTypeChanged"
                                               :disabled="wait" required>
                                    <option value="text">Short Answer</option>
                                    <option value="select">Dropdown</option>
                                    <option value="single">Single Selection</option>
                                    <option value="multiple">Multiple Selection</option>
                                    <option value="number">Number</option>
                                    <option value="date">Date</option>
                                    <option value="time">Time</option>
                                    <option value="duration">Duration</option>
                                    <option value="email">E-Mail</option>
                                    <!-- <option value="file">File Upload</option> -->
                                    <option value="narration">Narration</option>
                                    <option value="checkbox">Checkbox</option>
                                    <option value="gps">GPS</option>
                                    <option value="instruction">Instruction</option>
                                </b-form-select>
                            </div>

                            <template v-if="question.type !== 'instruction'">
                                <!-- Mark as required -->
                                <div class="mb-3">
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" v-model="question.required" :disabled="wait"
                                               type="checkbox" id="required_question">
                                        <label class="form-check-label form-label"
                                               for="required_question">Required
                                               <span class="form-label-tooltip" id="required">
                                            <i class="far fa-question-circle"></i>
                                            <b-tooltip target="required" placement="right">
                                                Enable this setting if you want the respondent to answer the question to proceed.
                                            </b-tooltip>
                                        </span>
                                            </label>
                                    </div>
                                </div>

                                <!-- Encrypt short answer -->
                                <div v-if="['text', 'email'].includes(question.type)" class="mb-3">
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" v-model="question.encrypt" :disabled="wait"
                                               type="checkbox" id="encrypt_input">
                                        <label class="form-check-label form-label" for="encrypt_input">
                                            Obscure answer
                                            <span class="form-label-tooltip" id="obscure">
                                                <i class="far fa-question-circle"></i>
                                                <b-tooltip target="obscure" placement="top">
                                                    Enable this setting if you want the system to generate random characters to hide the original response.
                                                </b-tooltip>
                                            </span>
                                        </label>
                                    </div>
                                </div>

                                <!-- Multiple Answers -->
                                <div v-if="['multiple', 'single','select', 'number'].includes(question.type)" class="mb-3">
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" v-model="question.multiple_answers"
                                               :disabled="wait"
                                               type="checkbox" id="multiple_answers">
                                        <label class="form-check-label form-label" for="multiple_answers">
                                            Additional information
                                            <span class="form-label-tooltip" id="add_information">
                                            <i class="far fa-question-circle"></i>
                                            <b-tooltip target="add_information" placement="top">
                                                Enable this setting if you  want to add more information about the selected option. 
                                            </b-tooltip>
                                        </span>
                                        </label>
                                    </div>
                                </div>

                                <!-- Placeholder for text inputs -->
                                <div v-if="['text', 'email', 'narration','number'].includes(question.type)"
                                     class="mb-3">
                                    <label class="form-label">Placeholder (Optional)</label>
                                    <b-form-input
                                        placeholder="Enter answer on the input"
                                        v-model="question.placeholder"
                                        type="text"
                                        :disabled="wait"
                                    >
                                    </b-form-input>
                                </div>

                                <!-- Options for radios, checkboxes & dropdowns -->
                                <div v-if="['multiple', 'select', 'single'].includes(question.type)" class="mb-3">
                                    <label class="form-label">Options</label>
                                    <b-form-textarea
                                        placeholder="Enter each option on a new line"
                                        rows="4"
                                        v-model="question.options"
                                        :disabled="wait">
                                    </b-form-textarea>

                                    <!-- Add 'other' option -->
                                    <div class="mb-3 mt-3">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" v-model="question.allow_other"
                                                   :disabled="wait"
                                                   type="checkbox"
                                                   id="other_option">
                                            <label class="form-check-label form-label" for="other_option">
                                                Add 'other' option
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <!-- Help text -->
                                <div class="mb-3">
                                    <label class="form-label">Help Text (Optional)</label>
                                    <b-form-textarea
                                        v-model="question.help"
                                        :disabled="wait" rows="1"
                                        placeholder="Add a comment that will appear below the input">
                                    </b-form-textarea>
                                </div>
                                <hr/>

                                <!-- Skip logic - show when option -->
                                <div class="mb-3 mt-3">
                                    <label class="form-label">Skip Logic</label><br>
                                    <label class="form-label">
                                        Show this question when<br/>(Trigger question)
                                        <span class="form-label-tooltip" id="show-when">
                                            <i class="far fa-question-circle"></i>
                                            <b-tooltip target="show-when" placement="top">
                                                Select the question that will be used as the reference for conditional branching.
                                            </b-tooltip>
                                        </span>
                                    </label>
                                    <!--                                    <multiselect-->
                                    <!--                                        style="font-size: 13px !important;padding: 2px !important;"-->
                                    <!--                                        :disabled="wait"-->
                                    <!--                                        v-model="selected_show_when_questions"-->
                                    <!--                                        :options="getShowWhenQuestions"-->
                                    <!--                                        :multiple="false"-->
                                    <!--                                        track-by="id"-->
                                    <!--                                        label="title"-->
                                    <!--                                        :preserve-search="true"-->
                                    <!--                                        :hide-selected="true"-->
                                    <!--                                        @change="setShowWhenOptions()"-->
                                    <!--                                        placeholder="Select question..."-->
                                    <!--                                        @input="setSelectedQuestion">-->
                                    <!--                                    </multiselect>-->

                                    <select v-model="question.show_when" class="form-select"
                                            @change="setShowWhenOptions()">
                                        <option value="">-- Select a question --</option>
                                        <option v-for="qn in getShowWhenQuestions" :key="qn.id"
                                                :value="qn.id">{{ qn.title }}
                                        </option>
                                    </select>
                                </div>

                                <!-- Skip logic - value is option -->
                                <div class="mb-3 mt-3">
                                    <label class="form-label">
                                        When the question(Trigger question) answer is...
                                        <span class="form-label-tooltip" id="operator">
                                            <i class="far fa-question-circle"></i>
                                            <b-tooltip target="operator" placement="right">
                                                Select the operator that will be used for comparison.
                                            </b-tooltip>
                                        </span>
                                    </label>
                                    <select v-model="question.operator" class="form-select">
                                        <option value="">--select--</option>
                                        <option value="==">Is equal to</option>
                                        <option value="!=">Is not equal to</option>

                                        <option value=">=">Is greater than or equal to</option>
                                        <option value="<=">Is less than or equal to</option>
                                        <option value="><">Is between</option>

                                        <option value="n">Is answered</option>
                                        <option value="!n">Is not answered</option>
                                    </select>
                                </div>

                                <div class="mb-3 mt-3">
                                    <label class="form-label">
                                        When the Answer is...
                                        <span class="form-label-tooltip" id="branch-value">
                                            <i class="far fa-question-circle"></i>
                                            <b-tooltip target="branch-value" placement="right">
                                                Select or input the answer choice to use in your logic.
                                            </b-tooltip>
                                        </span>
                                    </label>
                                    <input v-if="valueIsOptions.length==0" class="form-control"
                                           v-model="question.value_is"
                                           :disabled="['n', '!n'].includes(question.operator)">

                                    <select v-else v-model="question.value_is" class="form-select"
                                            :disabled="['n', '!n'].includes(question.operator)">
                                        <option value="">--select--</option>
                                        <option v-for="option in valueIsOptions" :key="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                </div>

                                <!-- Options for file input -->
                                <div v-if="['file'].includes(question.type)" class="mb-3">
                                    <label class="form-label">Select file types</label><br/>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="pdf" value="pdf">
                                        <label class="form-check-label" for="pdf">PDF</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="doc" value="doc">
                                        <label class="form-check-label" for="doc">DOC</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="doc2" value="doc">
                                        <label class="form-check-label" for="doc">DOC</label>
                                    </div>
                                </div>
                            </template>

                            <div class="mb-2">
                                <button
                                    type="submit"
                                    class="custom-btn custom-btn-primary custom-btn-full"
                                    :disabled="wait">
                                    <span v-if="question.id">Update Question</span>
                                    <span v-else>Add Question</span>
                                </button>
                            </div>

                          <div class="mb-2">
                            <button
                                type="clear"
                                @click.prevent="clearSkipLogic()"
                                class="custom-btn btn-warning custom-btn-full">
                              Clear Skip Logic
                            </button>
                          </div>

                            <div class="mb-2">
                                <button
                                    type="reset"
                                    @click.prevent="resetForm()"
                                    class="custom-btn custom-btn-danger custom-btn-full">
                                    Reset
                                </button>
                            </div>
                        </form>
                    </div>

                </b-sidebar>
            </div>

        </div>

    </div>
</template>

<script>
import Alerts from "../../Helpers/Alerts";
// import draggable from 'vuedraggable';
// import Multiselect from 'vue-multiselect'

export default {
    name: "Questions",
    components: {Alerts},
    props: ['code'],
    data() {
        return {
            isSidebarOpen: false,
            survey: {},
            questions: [],
            filetypes: ["pdf", "doc"],
            question: {
                id: "",
                title: "",
                type: "text",
                section_id: '',
                options: "",
                placeholder: "",
                help: '',
                allow_other: false,
                required: false,
                encrypt: false,
                multiple_answers: false,
                show_when: '',
                value_is: '',
                showInput: '',
                operator: ''
            },
            collapseCount: 3,
            collapseIndex: 1,
            valueIsOptions: [],
            sections: [],

            lang: 'en',
            languages: [],

            selectedQuestion: ''
        }
    },
    mounted() {
        this.loadSurvey()
        this.loadQuestions()

        this.loadLanguages()
        // this.loadSections()

        if (this.survey) {
            this.updateSurveyLang()
        }
    },
    computed: {
        getShowWhenQuestions() {
            return this.questions.filter(a => a.id != this.question.id)
        },
        letters() {
            let letters = []
            for (let i = "A".charCodeAt(0); i <= "Z".charCodeAt(0); i++) {
                letters.push(String.fromCharCode([i]))
            }
            return letters
        }
    },
    methods: {
        scrollTo(event) {
            document.getElementById(event.target.value).scrollIntoView({
                behavior: "smooth"
            })
        },
        setSelectedShownWhenQuestionId(qn) {
            this.question.show_when = qn.id;
        },
        updateSurveyLang() {
            let lang = undefined
            if (this.survey.languages) {
                lang = this.survey.languages.find(a => a.lang === this.lang)
            }

            if (lang !== undefined) {
                this.survey.title = lang.title
                this.survey.tags = lang.tags
                this.survey.description = lang.description
                this.langSet = true
            } else {
                this.survey.title = ''
                this.survey.tags = ''
                this.survey.description = ''
            }

            this.questions.map(qn => {
                let _lang = undefined
                if (qn.languages) {
                    _lang = qn.languages.find(b => b.lang === this.lang)
                }

                if (_lang !== undefined) {
                    qn.title = _lang.title
                    qn.options = _lang.options
                    qn.value_is = _lang.value_is
                    qn.getOptions = _lang.getOptions
                    qn.placeholder = _lang.placeholder
                } else {
                    qn.title = ''
                    qn.options = ''
                    qn.value_is = ''
                    qn.getOptions = ''
                    qn.placeholder = ''
                }
            })
        },
        loadLanguages() {
            this.$http.get('/api/languages').then(response => {
                this.languages = response.data.data
            })
        },
        questionTypeChanged() {
            if (this.question.type == 'gps') {
                this.question.help = "Enter latitude &amp; longitude (e.g., -1.3032051, 36.7073098)"
            } else {
                this.question.help = ''
            }

            if (this.question.show_when == false) {
                this.question.showInput = false
            }

        },
        setShowWhenOptions() {
            if (this.question.show_when) {
                let qn = this.questions.find(a => a.id == this.question.show_when)
                this.valueIsOptions = qn.getOptions
            }
        },
        openSidebar() {
            this.isSidebarOpen = true
        },
        loadSections() {
            this.$http.get(`/api/profile/survey/${this.code}/sections`).then(response => {
                this.sections = response.data.data
            })
        },
        loadSurvey() {
            this.wait = true
            this.clear()
            this.$http.get("/api/profile/survey/" + this.code).then(response => {
                this.survey = response.data.data
                this.updateSurveyLang()
            }).catch(error => {
                this.showError(error)
            }).then(() => {
                this.wait = false
            })
        },
        unPublishSurvey() {
            if (confirm('Are you sure you want to UNPUBLISH this survey?')) {
                this.wait = true
                window.scroll(0, 0)
                this.success = 'Unpublishing survey, please wait...'
                this.$http.post(`/api/profile/survey/${this.code}/unpublish`).then(response => {
                    this.survey = response.data.data
                    this.success = 'Survey unpublished successfully'
                }).catch(error => {
                    this.showError(error)
                }).then(() => {
                    this.wait = false
                    this.success = ''
                })
            }
        },
        addNewSection() {
            this.setLoading();
            this.clear();
            this.sections.push({
                questions: []
            })
        },
        loadQuestions() {
            this.wait = true
            this.clear()
            this.success = 'Gathering questions. Please wait..'
            this.$http.get(`/api/profile/survey/${this.code}/questions`).then(response => {
                this.lanes = response.data
                this.questions = response.data.data
                if (this.questions) {
                    this.updateSurveyLang()
                }
                this.success = ''
            }).catch(error => {
                this.showError(error)
            }).then(() => {
              this.wait = false
            })
        },
        createQuestion() {
            this.question.lang = this.lang
            this.$http.post(`/api/profile/survey/${this.code}/questions`, this.question).then(() => {
                this.$toaster.success('Question added successfully!')
                this.resetForm()
                this.loadQuestions()
            }).catch((error) => {
                this.showError(error)
            }).then(() => {
                this.wait = false;
            });
        },
        newQuestion() {
            this.openSidebar()
            this.resetForm()
        },
        editQuestion(question) {
            this.question = question
            this.openSidebar()
            this.setShowWhenOptions()
        },
        resetForm() {
            this.question = {
                id: "",
                title: "",
                type: "text",
                options: "",
                placeholder: "",
                help: "",
                required: false,
                encrypt: false,
                multiple_answers: false,
            }
        },
      clearSkipLogic() {
            this.question.show_when = '',
            this.question.value_is = ''
            this.question.operator = ''
            this.createQuestion()
      },
        removeQuestion(question) {
            if (confirm('Are you sure you want to delete this question?')) {
                this.$http.delete(`/api/profile/survey/${this.code}/questions?id=` + question.id).then(() => {
                    this.$toaster.success('Question deleted successfully!')
                    this.loadQuestions()
                }).catch((error) => {
                    this.showError(error)
                })
            }
            // const index = this.questions.indexOf(question);
            // if (index > -1) {
            //     this.questions.splice(index, 1);
            //     this.$toaster.warning('You have removed question ' + index)
            // }
        },
        onCollapseHidden() {
            const newCollapseIndex = this.collapseIndex + 1;
            this.collapseIndex = newCollapseIndex > this.collapseCount ? 1 : newCollapseIndex;
        }
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
</style>